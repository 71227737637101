<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <slot v-bind:on="on" name="activator"></slot>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text" text :dark="color1IsDark">
        <v-toolbar-title>Find Your Team</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          slot="extension"
          color="color3"
          append-icon="fas fa-search"
          label="Search"
          hide-details
          clearable
          v-model="searchTerm"
          solo-inverted
          autofocus
          class="mb-3"
        ></v-text-field>
      </v-toolbar>
      <loading-bar v-model="loading"></loading-bar>
      <v-card-text v-if="data.length > 0 || loading" class="pa-2">
        <div class="pa-5 text-center" v-if="loading && data.length === 0 ">
          <v-progress-circular indeterminate color="color3"></v-progress-circular>
        </div>
        <div>
          <v-switch
            label="Show Withdrawn"
            v-model="showWithdrawn"
            hide-details
            dense
            color="success"
            class="my-0 ml-3"
            v-if="data && isFiltered"
          ></v-switch>
        </div>
        <v-card v-for="item in filteredData" :key="item.id" class="ma-2">
          <v-card-text>
            <div>{{item.tournament}}</div>
            <div class="grey--text">{{item.division}}</div>
            <div class="mt-3">
              <b>{{item.teamName}}</b>
            </div>
            <div class="grey--text" v-for="p in item.players" :key="p">{{p}}</div>
            <div class="text-center">
              <v-btn
                text
                small
                @click="dialog = false"
                style="z-index: 1000"
                :to="{name: 'division-team', params: {tournamentId: item.tournamentId, teamId: item.teamId}}"
              >
                View Team
                <v-icon color="color3" class="ml-3">fas fa-caret-right</v-icon>
              </v-btn>
            </div>
          </v-card-text>
          <v-overlay
            :absolute="true"
            v-if="item.isDeleted"
          >
            <div class="title">Withdrawn</div>
          </v-overlay>
        </v-card>
      </v-card-text>
      <v-card-text v-else class="pa-12 text-center title">
        Search by entering a team name or player name above
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  props: ['username', 'open'],
  data () {
    return {
      loading: false,
      summary: false,
      data: [],
      page: { itemsPerPage: 25 },
      searchTerm: null,
      exporting: false,
      dialog: false,
      showWithdrawn: false
    }
  },
  computed: {
    ...mapGetters(['color1IsDark']),
    filteredData () {
      return this.showWithdrawn ? this.data : this.data.filter(f => !f.isDeleted)
    },
    isFiltered () {
      return !!this.data.find(f => !f.isDeleted)
    },
    headers () {
      return [
        {
          text: '',
          align: 'left',
          sortable: true,
          value: 'event',
          show: true
        },
        {
          text: 'Team',
          align: 'left',
          sortable: true,
          value: 'teamName',
          show: false
        },
        { text: 'Players', value: 'playerNames', show: false },
        { text: 'Field', value: 'field', show: false },
        {
          value: 'actions',
          sortable: false,
          show: false
        }
      ].filter(f => f.show)
    }
  },
  methods: {
    search: debounce(function () {
      this.getRegistrations()
    }, 333),
    getRegistrations () {
      if (!this.searchTerm || typeof this.searchTerm !== 'string' || this.searchTerm.length < 3) return

      this.loading = true
      this.$VBL.organization.registrations.search(this.username, this.searchTerm)
        .then((response) => {
          if (response.data.term === this.searchTerm) {
            this.data = response.data.results
            this.data.forEach((d) => {
              d.dtRegisteredF = moment(d.dtRegistered).format('L LT')
              d.unix = +moment(d.dtRegistered)
              d.players = d.playerNames.split(', ')
            })
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error.response)
          this.loading = false
        })
    }
  },
  watch: {
    searchTerm: 'search',
    open: function (v) {
      if (v) this.dialog = true
    },
    dialog: function (v) {
      if (!v) {
        this.searchTerm = null
        this.data = []
        this.$emit('closed')
      }
    }
  }
}
</script>

<style>
  .deleted {
    text-decoration: line-through
  }
</style>
